import styled from "styled-components"

export const Img = styled.div`
  background-color: #4e4e4e;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;

  img {
    margin: 0;
    width: 20px;
    height: 20px;
  }
`
