import styled from "styled-components"
import phone from "../../../assets/icons/call.svg"
import car from "../../../assets/icons/car.svg"
import deliveroo from "../../../assets/icons/deliveroo.svg"

export const Facebook = styled.img`
  width: 23px;
  margin-right: 1rem;
  @media (max-width: 960px) {
    width: 25px;
  }
`;

export const Instagram = styled.img`
  width: 23px;
  margin-right: 1rem;
  @media (max-width: 960px) {
    width: 25px;
  }
`;

export const Phone = styled.div`
  width: 15px;
  height: 15px;
  background-image: url(${phone});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 0.5rem 0 0.3rem;
  
  @media (max-width: 960px) {
    margin-left: 0;
  }
`;

export const Car = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${car});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 0.5rem 0 0.3rem;
  @media (max-width: 960px) {
    margin-left: 0;
  }
`;

export const Deliveroo = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${deliveroo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 0.5rem 0 0.3rem;
  @media (max-width: 960px) {
    margin-left: 0;
  }
`;
