import React from "react"
import styled, { keyframes } from "styled-components"


const Wrapper = styled.span`
  span {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
    border-radius: 50%; 
  }

  &.main-wrapper {
    position: absolute;
  }

  &.dots-container {
    position: relative;
  }
`

const dot1 = keyframes`
  0% {
    opacity: .2;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(5);
  }
`

const dot2 = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(3);
  }
`

const dot3 = keyframes`
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);
  }
`

const Dot1 = styled.span`
  width: 13px;
  height: 13px;
  border: 5px solid #336A03;
  background-color: transparent;
  z-index: 1;
  opacity: .2;
  animation: ${dot1} 1.5s infinite;
`
const Dot2 = styled.span`
  width: 13px;
  height: 13px;
  background-color: #336A03;
  z-index: 2;
  animation: ${dot2} 1.5s infinite;
`
const Dot3 = styled.span`
  width: 13px;
  height: 13px;
  background-color: #336A03;
  z-index: 3;
  animation: ${dot3} 1s alternate infinite;
`


export const Radar = () => {
  return (
    <Wrapper className="main-wrapper">
      <span className="dots-container">
        <Dot1 className="dot1" />
        <Dot2 className="dot2" />
        <Dot3 className="dot3" />
      </span>
    </Wrapper>
  )
}
