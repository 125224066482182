import styled from "styled-components"
import footer from "../../../assets/illustrations/footer.svg"

export const Wrapper = styled.div`
  padding: 4rem 0 1rem 0;
  background-image: url(${({ overlay }) => (overlay ? footer : "")});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`
export const Details = styled.div`
  text-align: center;
font-family: Montserrat, sans-serif;
  span {
    font-size: 12px;
    //color: #336A03;
  }
  img {
    margin-bottom: 0.6rem;
  }
  //
  //@media (max-width: 680px) {
  //  margin-bottom: 2rem;
  //}
`
