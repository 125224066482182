import styled from "styled-components"

export const SocialLink = styled.a`
  margin-right: 0 !important;
`

export const OrderLink = styled.a`
	display: flex;
  border-radius: 30px;
	margin-right: 1rem !important;
	height: 20px;
`


export const Wrapper = styled.div`
  a {
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
		letter-spacing: 1.1px;
  }
	@media (max-width: 960px) {
		a {
			font-size: 22px;
		}
	}

  a:hover {
    color: #efaa7c;
  }
  ${({ desktop }) =>
          desktop
                  ? `
			align-items: center;
			display: flex;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1em;

					&:last-child {
							margin-right: unset;
					}
					
					&:nth-child(5) {
							margin-right: 1rem;
					}
			}
		`
                  : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1.2rem;

					&:last-child {
							margin-bottom: unset;
					}
					&:nth-child(7), &:nth-child(8) {
							padding: 0;
					}
			}
	`}
`

export const SocialLinks = styled.div`
	display: flex;
	width: 60px;
	justify-content: space-between;
	@media (max-width: 960px) {
		width: 80px;
		margin-top: 0.5rem;
	}
`
