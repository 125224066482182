import styled from "styled-components"

export const Paragraph = styled.p`
  margin-right: 1.5rem;
  color: #4e4e4e;
  font-size: 1.5rem;
  letter-spacing: 0.8px;
  opacity: 0.7;
  font-weight: 100;
  @media (max-width: 680px) {
    margin: 0;
  }
  
  span {
    @media (max-width: 680px) {
      display: none;
    }
  }
`
