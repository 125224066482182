import React from "react"
import { Wrapper, Details } from "./styles"
import config from "../../../data/config"
import logo from "../../../assets/icons/logo.svg"

export const Footer = ({ overlay }) => (
  <footer>
    <Wrapper overlay={overlay}>
      <Details>
        <div>
          <img src={logo} alt="Kakilang logo" height="50px"/>
        </div>
        <div>
          <span>© {new Date().getFullYear()} All rights are reserved by {config.legalName}</span>
        </div>
      </Details>
    </Wrapper>
  </footer>
)
