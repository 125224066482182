module.exports = {
  defaultTitle: 'Kakilang - Bubble tea & Asian street food.',
  author: 'Chris',
  url: 'https://www.kakilang.ie',
  legalName: 'Kakilang',
  defaultDescription: 'Kakilang - Bubble tea & Asian street food.',
  socialLinks: {
    instagram: 'https://www.instagram.com/kakilang.ie/',
    facebook: 'fb://profile/101928841225867',
  },
  deliverLink: {
    uberEat: 'https://www.ubereats.com/ie/dublin/food-delivery/kakilang/EGdcuW5eQrKKglFPbPcU-A'
  },
  themeColor: '#4e4e4e',
  backgroundColor: '#ECEAE0',
  social: {
    facebook: '@kakilang.ie',
    instagram: '@kakilang.ie',
  },
  address: {
    full: '5 Bachelors Walk Dublin, Ireland',
    city: 'Dublin',
    country: 'Ireland',
    eirCode: 'D01 RT02',
  },
  contact: {
    email: 'hello@kakilang.ie',
    phone: '01-878 8172',
  },
  foundingDate: '2020',
};
