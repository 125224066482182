import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const StickyHeader = styled.header`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;

  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
  -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
`

export const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: none;
  transition: 0.4s;

  ${({ sidebar }) =>
    sidebar &&
    `
			display: block;
			z-index: 4;	
	`}
`;
