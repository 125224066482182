import React from "react"
import { Link } from "gatsby"
import { Container } from "../../../common"
import NavbarLinks from "../NavbarLinks"
import { Wrapper } from "./styles"
import logo from "../../../../assets/icons/logo_top.svg"

const Navbar = () => {
  return (
    <Wrapper as={Container}>
      <Link to="/">
        <img src={logo} alt="Kakilang logo" height="25px"/>
      </Link>
      <NavbarLinks desktop test/>
    </Wrapper>
  );
};

export default Navbar
