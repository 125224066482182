import React, { useContext } from "react"
import Navbar from "./Navbar"
import Hamburger from "./Hamburger"
import Sidebar from "./Sidebar"
import { Wrapper, Overlay, StickyHeader } from "./styles"
import { SidebarContext } from "../../../providers/SidebarProvider"

export const Header = () => {
  const { showDrawer, toggleDrawer } = useContext(SidebarContext);

  return (
    <StickyHeader>
      <Wrapper>
        <Overlay sidebar={showDrawer} onClick={toggleDrawer} />
        <Navbar />
        <Hamburger sidebar={showDrawer} toggle={toggleDrawer} />
        <Sidebar sidebar={showDrawer} toggle={toggleDrawer} />
      </Wrapper>
    </StickyHeader>
  )
}
