import styled from "styled-components"


export const GoogleMapSection = styled.div`
  height: 100%;
  width: 100%;
  
  @media (max-width: 960px) {
    height: 500px;
  }

  @media (max-width: 680px) {
    height: 400px;
  }
`
