import React from "react"
import { Wrapper, SocialLink, OrderLink, SocialLinks } from "./styles"
import { graphql, Link, useStaticQuery } from "gatsby"
import fb from "../../../../assets/icons/facebook.svg"
import ig from "../../../../assets/icons/instagram.svg"
import { Car, Deliveroo, Facebook, Instagram, Phone } from "../../../common"

const NavbarLinks = ({ desktop }) => {
  const data = useStaticQuery(
    graphql`
        query {
            contentfulShopInformation {
              facebookLinkDesktop
              facebookLinkPhone
              instagramLink
              delivery1
              delivery2
              phone
              voucherLink
            }
          }
    `
  )
  const { contentfulShopInformation } = data;
  return (
    <Wrapper desktop={desktop}>
      {(!desktop)&&<Link to="/">Home</Link>}
      <Link to="/menu">Menu</Link>
      <a href="/#about">About</a>
      <a href="/#contact">Contact</a>
      <Link to="/join_us">Join Us</Link>
      <a href={contentfulShopInformation.voucherLink} target="_blank" rel="noopener noreferrer">Gift Voucher</a>
      <OrderLink target="_blank" href={"tel:" + contentfulShopInformation.phone}>
        <Phone/>Order Now
      </OrderLink>
      <OrderLink href={contentfulShopInformation.delivery1} target="_blank" rel="noopener noreferrer">
        <Car />Uber Eats
      </OrderLink>
      <OrderLink href={contentfulShopInformation.delivery2} target="_blank" rel="noopener noreferrer">
        <Deliveroo /> Deliveroo
      </OrderLink>
      <SocialLinks>
        <SocialLink href={contentfulShopInformation.facebookLinkDesktop} target="_blank" rel="noopener noreferrer">
          <Facebook src={fb} alt="Facebook icon" />
        </SocialLink>
        <SocialLink href={contentfulShopInformation.instagramLink} target="_blank" rel="noopener noreferrer">
          <Instagram src={ig} alt="Instagram icon" />
        </SocialLink>
      </SocialLinks>
    </Wrapper>
  )
}

export default NavbarLinks
