import styled from 'styled-components';

export const ArrowDown = styled.div`
  @keyframes arrows {
    0% {
      border-left:5px solid rgba(0,0,0,0);
      border-bottom:5px solid rgba(0,0,0,0);
      transform:translate(5px, -15px)rotate(-45deg);
    }
    10%, 90% {
      border-left:5px solid rgba(0,0,0,0);
      border-bottom:5px solid rgba(0,0,0,0);
    }
    50% {
      border-left:5px solid rgba(0,0,0,1);
      border-bottom:5px solid rgba(0,0,0,1);
      transform:translate(5px,0px)rotate(-45deg);
    }
    100% {
      border-left:5px solid rgba(0,0,0,0);
      border-bottom:5px solid rgba(0,0,0,0);
      transform:translate(5px,15px)rotate(-45deg);
    }
  }

  width:10px;
  height:10px;
  margin: 0 auto;
  transform:translate(-50%,-50%);
  
  &:before {
    content: '';
    position: absolute;
    width:100%;
    height:100%;
    top: 1rem;
    border-left:5px solid rgba(0,0,0,1);
    border-bottom:5px solid rgba(0,0,0,1);
    transform:translate(5px, 15px)rotate(-45deg);
    animation: arrows 3s linear infinite;
  }
  
  &:after {
    content: '';
    position: absolute;
    width:100%;
    height:100%;
    top: 1rem;
    border-left:5px solid rgba(0,0,0,1);
    border-bottom:5px solid rgba(0,0,0,1);
    transform:translate(5px,5px)rotate(-45deg);
    animation: arrows 3s linear infinite -1.5s;
  }
`



