import React from "react"
import Zoom from "react-reveal/Zoom"
import styled from "styled-components"

export const Text = styled.h1`
  margin: 0 0 2rem;
  font-size: 3rem;
  font-weight: bolder;
  text-align: center;
  padding: 0 16px;
`

export const Title = ({ children }) => (
  <Zoom cascade duration={1500}>
    <Text>{children}</Text>
  </Zoom>
)
