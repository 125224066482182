import React from 'react';
import { Footer } from '../index';
import { Global } from './styles';
import "./layout.css"

export const Base = ({ children, overlay }) => {
  return (
    <>
      <Global />
      {children}
      <Footer overlay={overlay}/>
    </>
  );
};
