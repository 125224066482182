import styled from "styled-components"

export const Wrapper = styled.div`
  visibility: hidden;
  position: fixed;
  top: 70%;
  right: 20px;
  z-index: 5;

  @media (min-width: 960px) {
    display: none;
  }
  @media (max-width: 400px) {
    top: 55%;
  }

  li ::after {
    content: attr(label);
    text-align: center;
    width: 55px;
    right: 55px;
    position: absolute;
    padding: 4px 5px;
    border-radius: 3px;
    color: rgba(1, 1, 1, 0.8);
    background-color: rgb(255 255 255 / 0.6);
    top: 35%;
    margin-top: -3.5px;
    font-size: 10px;
    opacity: 1;
    transition: all 0.3s;
    box-sizing: inherit;
    font-family: Montserrat, sans-serif;
  }
`

export const Icon = styled.div`
  padding: 0.7rem;
`

export const Icon2 = styled.a`
  padding: 0.8rem;
  display: block;
`
