import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Radar } from './Radar';
import { theme } from "./theme"
import { GoogleMapSection } from "./style"

const GoogleMaps = () => {
  const mapConfig = {
    center: {
      lat: 53.34682591301693,
      lng: -6.2627847185672545,
    },
    zoom: 16,
  };
  return (
    <GoogleMapSection id="google-map-section">
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.GATSBY_GOOGLE_MAP_API_CREDENTIAL}` }}
        defaultCenter={mapConfig.center}
        defaultZoom={mapConfig.zoom}
        options={{
          styles: theme,
          fullscreenControl: false,
          mapTypeControl: false,
          scaleControl: false,
          zoomControlOptions: {
            position: 1,
          },
        }}
      >
        <Radar lat={mapConfig.center.lat} lng={mapConfig.center.lng} />
      </GoogleMapReact>
    </GoogleMapSection>
  );
};

export default GoogleMaps;
