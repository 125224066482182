import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu"
import bubble_tea from "../../../assets/icons/bubble-tea.svg"
import close from "../../../assets/icons/close.svg"
import car from "../../../assets/icons/car.svg"
import deliveroo from "../../../assets/icons/deliveroo.svg"
import call from "../../../assets/icons/phone-call.svg"
import { Icon, Icon2, Wrapper } from "./style"


export const FloatMenu = ({show, direction}) => {
  const [isOpen, setOpen] = useState(false)
  const [floatBtnStyle, setFloatBtnStyle] = useState({})
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = show || (Math.abs(currPos.y) > window.innerHeight)

      const shouldBeStyle = {
        visibility: isVisible ? "visible" : "hidden",
        display: isVisible ? "" : "none",
        transform: isVisible ? "none" : "translate(0, -100%)"
      }

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(floatBtnStyle)) return

      setFloatBtnStyle(shouldBeStyle)
    },
    [floatBtnStyle]
  )
  const data = useStaticQuery(
    graphql`
        query {
            contentfulShopInformation {
              delivery1
              delivery2
              phone
            }
          }
    `
  )
  const { contentfulShopInformation } = data
  return (
    <Wrapper style={{ ...floatBtnStyle }} id="FloatingMenu">
      <FloatingMenu
        slideSpeed={500}
        direction={direction}
        spacing={15}
        isOpen={isOpen}
      >
        <MainButton
          iconResting={<Icon><img src={bubble_tea} alt="bubble tea" /></Icon>}
          iconActive={<Icon><img src={close} alt="close" /></Icon>}
          background="#4e4e4e"
          onClick={(event) => {
            event.preventDefault();
            setOpen(!isOpen)
            }
          }
          size={65}
        />
        <ChildButton
          icon={<Icon2 href={contentfulShopInformation.delivery1} target="_blank" rel="noopener noreferrer"><img src={car} alt="Uber Eat" /></Icon2>}
          background="#ECEAE0"
          size={50}
          label={"Uber Eat"}
        />
        <ChildButton
          icon={<Icon2 href={contentfulShopInformation.delivery2} target="_blank" rel="noopener noreferrer"><img src={deliveroo} alt="Deliveroo" /></Icon2>}
          background="#ECEAE0"
          size={50}
          label={"Deliveroo"}
        />
        <ChildButton
          icon={
            <Icon2 target="_blank" href={"tel:" + contentfulShopInformation.phone}><img src={call} alt="Call" /></Icon2>
          }
          background="#ECEAE0"
          size={50}
          label={"Order Now"}
        />
      </FloatingMenu>
    </Wrapper>
  )
}
